import request from "../utils/request";

export function get_store_type_list(data: JSON){
    return request({
        url: "/getStoreTypeList",
        method: "post",
        data: data
    });
}

export function get_store_type_detail(data: JSON){
    return request({
        url: "/getStoreTypeDetail",
        method: "post",
        data: data
    });
}

export function get_store_type_manage_list(data: JSON){
    return request({
        url: "/getStoreTypeManageList",
        method: "post",
        data: data
    });
}

export function save_store_type_manage_list(data: JSON){
    return request({
        url: "/saveStoreTypeManageList",
        method: "post",
        data: data
    });
}

export function save_store_type(data: JSON){
    return request({
        url: "/saveStoreType",
        method: "post",
        data: data
    });
}

export function save_store_subtype(data: JSON){
    return request({
        url: "/saveStoreSubType",
        method: "post",
        data: data
    });
}